import { useCallback, useEffect } from 'react'
import { useAtom } from 'jotai'

// Utils
import { userBySlug } from './atoms'
import api from '../../apis'
import User from '../../types/User'
import useAsync from '../../hooks/useAsync'

const useUserBySlug = (slug: string) => {
  const [users, setUser] = useAtom(userBySlug)
  const user = users[slug] || null

  const fetchUser = useCallback(async () => api.user.getUser(slug), [slug])
  const { execute, status, response, error } = useAsync(fetchUser, false)

  /**
   * Fetch user if it's not in a global state (fetched already)
   */
  useEffect(() => {
    if (user === null) {
      execute()
    }
  }, [user, execute])

  /**
   * Manage errors
   */
  useEffect(() => {
    if (error && status === 'error') {
      const status = error.response.status

      if (status === 404) {
        error.handle404()
      } else {
        error.handleDefault()
      }
    }
  }, [error, status])

  /**
   * Manage success - Save user to global state
   */
  useEffect(() => {
    if (response && status === 'success') {
      const userObj = new User(response.data)
      setUser({ slug, user: userObj })
    }
  }, [slug, response, status, setUser])

  return {
    isLoading: status === 'pending',
    user,
    reloadUser: execute,
  }
}

export default useUserBySlug
