import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'

// Global Components
import { NakedLayout } from '../../../components/Layout'
import Footer from '../../../components/Footer'
import ProfileUserCard from '../../../components/UserCard/ProfileUserCard'
import UserMenu from '../../../components/UserMenu'
import Loading from '../../../components/Loading/ThreeDotsContent'

// Local Components
import GoBackButton from '../_components/GoBackButton'

// Utils
import useAuth from '../../../state/auth/useAuth'
import useUserBySlug from '../../../state/user/useUserBySlug'
import { ALLOWED_LINKS } from '../../../components/Form/UpdateTutorProfile/constants'
import { getHostname } from '../../../utils/url'
import { capitalize } from '../../../utils/string'
import ROUTES from '../../../config/routes'

const Profile = ({ slug }) => {
  const { t: t_common } = useTranslation('common')
  const { t } = useTranslation('pages')

  const { isLoaded: isAuthLoaded, user: authUser } = useAuth()

  const { isLoading, user } = useUserBySlug(slug)
  const [links, setLinks] = useState([])

  const customUrlLabel = t('profile.customUrlLabel')

  useEffect(() => {
    if (!user) {
      return // user not found
    }

    const getCustomLinkLabel = (link) => {
      const hostname = getHostname(link.link)

      if (!hostname) {
        return customUrlLabel
      }

      return capitalize(hostname)
    }

    const filteredLinks = ALLOWED_LINKS.filter(
      ({ id }) => !!user.links.find((link) => link.id === id),
    ).map(({ id, label }) => {
      const link = user.links.find((link) => link.id === id)
      link.label = label ? label : getCustomLinkLabel(link)
      return link
    })

    setLinks(filteredLinks)
  }, [user, customUrlLabel])

  return (
    <NakedLayout isHeightFull bodyClassName={'bg-gray-100'}>
      <main className="relative">
        <div className="flex flex-col w-full">
          <div
            className={
              'w-full max-w-lg mx-auto px-4 mt-5 lg:mt-6 flex items-center justify-between relative'
            }
          >
            <GoBackButton onClick={() => navigate(ROUTES.goBack)} />

            {isAuthLoaded && authUser && (
              <UserMenu
                isWhite
                items={[
                  {
                    type: 'profile',
                  },
                  {
                    href: ROUTES.settings,
                    label: t_common('navbar.userMenu.settings'),
                  },
                  {
                    type: 'logout',
                  },
                ]}
              />
            )}
          </div>

          <div className="w-full max-w-lg mx-auto mt-7 mb-20 px-4">
            {isLoading && <Loading />}

            {isLoading === false && user && (
              <ProfileUserCard
                image={user.getAvatar(200)}
                name={user.getFullName()}
                bio={user.bio}
                buttonLabel={t('profile.ctaButtonLabel')}
                links={links}
              />
            )}
          </div>
        </div>

        <Footer className={'max-w-7xl px-2 border-t border-gray-200'} />
      </main>
    </NakedLayout>
  )
}

export default Profile
