import { atom } from 'jotai'
import User from '../../types/User'

const userInitData: { [key: string]: User } = {}

const userDataAtom = atom(userInitData)

export const userBySlug = atom(
  (get) => get(userDataAtom),
  (get, set, data: { slug: string; user: User }) =>
    set(userDataAtom, {
      ...get(userDataAtom),
      [data.slug]: data.user,
    }),
)
