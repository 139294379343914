import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GoBackButton = ({ onClick }) => {
  return (
    <button className={'bg-transparent focus:outline-none ml-2'} onClick={onClick}>
      <FontAwesomeIcon className={'text-gray-600 text-2xl'} icon={['fas', 'arrow-left']} />
    </button>
  )
}

export default GoBackButton
