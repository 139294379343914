import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import classnames from 'classnames'

const ProfileUserCard = ({ image, name, bio, links, buttonLabel, onClick }) => {
  return (
    <div className="w-full mt-20 bg-white relative shadow rounded-lg">
      <div className="flex justify-center">
        <div className="rounded-full bg-white mx-auto absolute -top-20 mt-3.5 w-32 h-32 shadow-md border-4 border-white">
          <Avatar round fgColor={'white'} color={'black'} src={image} name={name} size="120" />
        </div>
      </div>

      <div className={classnames('mt-20', { 'pb-6': links.length === 0 })}>
        <h1 className="font-bold text-center text-3xl text-gray-900">{name}</h1>

        {bio && <p className="text-center text-sm text-gray-400 font-medium">{bio}</p>}

        <button onClick={onClick} className="btn btn-primary block mx-auto mt-4">
          {buttonLabel}
        </button>

        {links.length > 0 && (
          <div className="w-full">
            <div className="mt-5 w-full flex flex-col items-center overflow-hidden text-sm">
              {links.map(({ id, link, label }) => (
                <a
                  key={id}
                  href={link}
                  target={'_blank'}
                  rel="noreferrer"
                  className={
                    'w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150'
                  }
                >
                  {label}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ProfileUserCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
}

export default ProfileUserCard
