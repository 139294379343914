import React from 'react'
import { Router } from '@reach/router'
import Profile from './_pages/Profile'

const ProfilePage = () => {
  return (
    <Router basepath={'/profile/'}>
      <Profile path={'/:slug'} />
    </Router>
  )
}

export default ProfilePage
